import { Outlet, redirect, useRouteLoaderData, type LoaderFunctionArgs } from "react-router";
import { TooltipProvider } from "./components/ui/tooltip";
import { getFeedUrl } from "./feed";
import { getAppToken } from "./user/token.server";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const token = await getAppToken(request, { user: true });
  if (!token) throw redirect("/home");
  const { user } = token;
  const feedUrl = await getFeedUrl(user.userId);
  return {
    user: {
      ...user,
      devMode: user.email === "kaspars@whimsical.com",
    },
    feedUrl,
  };
};

export function useUser() {
  const user = useRouteLoaderData<typeof loader>("with-user")?.user;
  if (!user) {
    throw new Error("No user");
  }
  return user;
}

export function useFeedUrl() {
  const data = useRouteLoaderData<typeof loader>("with-user");
  if (!data) {
    throw new Error("No data");
  }
  return data.feedUrl;
}

export type User = ReturnType<typeof useUser>;

export function userFromMatches(matches: { id: string; data: unknown }[]) {
  const data = matches.find((m) => m.id === "with-user")?.data as Awaited<ReturnType<typeof loader>>;
  if (!data) throw new Error("No data");
  return data.user;
}

export default function WithUser() {
  return (
    <TooltipProvider>
      <Outlet />
    </TooltipProvider>
  );
}
